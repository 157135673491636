<template>
  <div
    class="echart"
    ref="echartPie"
    :style="{ float: 'left', width: '80%', height: '330px' }"
  ></div>
</template>

<script>
import * as echarts from 'echarts'
import eChartBase from './eChartBase'

export default {
  name: 'ChartPie',

  methods: {
    //   重载方法，获取echarts实例
    getEchartInst() {
      return echarts.init(this.$refs.echartPie)
    },
    // 重载方法，设置options
    getOption() {
      if (this.dChartOption) {
        return this.dChartOption
      }
      const option = {
        title: {
          text: this.dChartTitle,
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
        },
        // legend: {
        //   data: [this.chartName],
        // },
        series: [
          {
            name: this.dChartName,
            type: 'pie',
            radius: '50%',
            label: {
              show: this.dShowData,
              formatter: '{b}: {c}',
              // normal: {
              //   show: false,
              //   formatter: '{b}: {c}',
              //   // position: ''
              // },
            },
            data: this.dChartXData,
          },
        ],
      }
      return option
    },
  },
  extends: eChartBase,
}
</script>

<style lang="scss" scoped>
</style>
