<script>
export default {
  mounted() {
    this.initEchartParms()
    this.initChart()
  },
  watch: {
    chartXData: {
      handler() {
        this.updateEchart()
      },
    },
    chartYData: {
      handler() {
        this.updateEchart()
      },
    },
  },
  props: {
    //   echart标题
    chartTitle: null,
    // echart series 名称
    chartName: null,
    // echart数据
    chartXData: null,
    // echart数据
    chartYData: null,
    // 是否显示数值
    showData: null,
    // chart option
    chartOption: null,
  },
  data() {
    return {
      dChartTitle: '',
      dChartName: '',
      dChartXData: [],
      dChartYData: [],
      dShowData: false,
      dChartOption: null,
      echartInst: null,
    }
  },
  methods: {
    //   重载方法，获取echarts实例
    getEchartInst() {
      return null
    },
    // 重载方法，设置options
    getOption() {
      if (this.dChartOption) {
        return this.dChartOption
      }
      return null
    },
    // 重载方法，是否空数据
    isNullData() {
      if (this.dChartXData.length === 0) {
        return true
      }
      return false
    },
    // 重载方法，mount时调用初始化echart参数
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    initSpecParmsWhenMount() {},
    // 初始化echart参数
    initEchartParms() {
      if (this.chartTitle) {
        this.dChartTitle = this.chartTitle
      }
      if (this.chartName) {
        this.dChartName = this.chartName
      }
      if (this.chartXData) {
        this.dChartXData = this.chartXData
      }
      if (this.chartYData) {
        this.dChartYData = this.chartYData
      }
      if (this.showData) {
        this.dShowData = this.showData
      }
      if (this.chartOption) {
        this.dChartOption = this.chartOption
      }
      //   特殊初始化方法
      this.initSpecParmsWhenMount()
    },
    // 初始化echart
    initChart() {
      this.echartInst = this.getEchartInst()
      const option = this.getOption()
      this.showLoading()
      this.echartInst.setOption(option, true)
      window.addEventListener('resize', () => {
        this.echartInst.resize()
      })
    },
    // 更新图标数据
    updateEchart() {
      const option = this.getOption()
      if (!this.echartInst) {
        this.echartInst = this.getEchartInst()
      }
      this.initEchartParms()
      this.showLoading()
      this.echartInst.setOption(option, true)
    },
    // 显示chart
    showLoading() {
      if (this.isNullData()) {
        this.echartInst.showLoading({
          text: '暂无数据',
          fontSize: 20,
          showSpinner: false,
        })
      } else {
        this.echartInst.hideLoading()
      }
    },
  },
}
</script>
