import { httpDelete, httpGet, httpPost, httpPut } from '../request'
import store from '@/store'

export function getUserList(parms: any) {
    const url = 'user/list'
    return httpGet(url, parms)
}

export function addUserInfo(data: any) {
    const url = 'user/list'
    return httpPost(url, data)
}

export function deleteUser(id: number | string) {
    const url = 'user/info/' + id
    return httpDelete(url)
}

export function getUserInfo() {
    return httpGet('user/info-self').then(res => {
        store.dispatch('setUser', res.data)
    })
}

export function userRegister(data: any) {
    return httpPost('user/register', data)
}

export function checkUser(userId: string) {
    const url = 'user/check-user/' + userId
    return httpGet(url)
}

export function updateUserInfo(userId: string, data: any) {
    const url = 'user/info/' + userId
    return httpPut(url, data)
}

export function updateUserPassword(data: any) {
    return httpPut('user/info-self/upd-password', data)
}

export function resetUserPassword(data: any) {
    return httpPut('user/reset-password', data)
}

export function sendRegisterSms(data: any) {
    return httpPost('user/register-sms', data)
}

export function sendResetPwdSms(data: any) {
    return httpPost('user/reset-pwd-sms', data)
}

export function forgetPassword(data: any) {
    return httpPut('user/forget-password', data)
}
