
<script>
import { httpPost } from '@/api/request'
import { getUserInfo } from '@/api/user-api/userApi'
import { getRandomInt } from '@/utils'
import { LOGIN_IMAGE_COUNT } from '@/const'
// import Base from '@/views/Base.vue'

export default {
  mounted() {
    const state = localStorage.getItem('rememberState')
    if (state === 'true') {
      this.rememberState = true
      this.username = localStorage.getItem('username')
      this.password = localStorage.getItem('password')
    } else {
      this.rememberState = false
    }
    // window.onresize = () => {
    //   window.screenWidth = document.body.clientWidth
    //   this.screenWidth = window.screenWidth
    // }
  },
  data() {
    return {
      // 记住用户密码
      rememberState: true,
      // 有效性验证
      valid: true,
      username: '',
      password: '',
      showPassword: false,
      rules: {
        required: (value) => !!value || '当前字段不允许为空',
        min: (v) => v.length >= 8 || '密码不能少于8个字符',
      },
      // login url，区分网页和app
      loginUrl: 'auth/login'
    }
  },
  methods: {
    // 用户登录
    signIn() {
      const data = {
        username: this.username,
        password: this.password,
      }
      httpPost(this.loginUrl, data)
        .then(() => {
          // 获取用户信息
          getUserInfo().then(() => {
            this.$router.push('/home')
          })
          // 记住用户
          this.rememberUser()
        })
    },
    // 用户注册
    register() {
      this.$router.push('/register')
    },
    // 忘记密码
    forgetPassword() {
      this.$router.push('/forget-password')
    },
    // 保存用户信息
    rememberUser() {
      localStorage.setItem('rememberState', this.rememberState)
      if (this.rememberState) {
        localStorage.setItem('username', this.username)
        localStorage.setItem('password', this.password)
      } else {
        localStorage.setItem('username', '')
        localStorage.setItem('password', '')
      }
      return false
    },
  },
  computed: {
    loginImage() {
      // 多个图片中随机选择
      const imgIdx = getRandomInt(LOGIN_IMAGE_COUNT)
      const imgName = 'login_' + imgIdx + '.png'
      return require('../../assets/loginImages/' + imgName)
    },
  },
  // extends: Base
}
</script>

<style lang="scss" scoped>
</style>
