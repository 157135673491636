import axios, { AxiosRequestConfig, Method } from 'axios'
// import router from '../router'
import router from '@/router'

// const BASEURL = 'http://127.0.0.1:8000/logic-test/api/v1/'
const BASEURL = '/logic-test/api/v1/'
export const HTTP_GET: Method = 'GET'
export const HTTP_POST: Method = 'POST'
export const HTTP_PUT: Method = 'PUT'
export const HTTP_DELETE: Method = 'DELETE'
export const HTTP_PATCH: Method = 'PATCH'

const apiInst = axios.create({
  withCredentials: true,
  baseURL: BASEURL,
  timeout: 5000
})

apiInst.interceptors.response.use(
  response => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    return response.data
  },
  error => {
    if (error.response.status === 403) {
      router.push('/login')
    } else if (error.response.status >= 500) {
      alert('系统错误，请联系管理员!')
    } else if (error.response.status === 400) {
      alert(error.response.data.reason)
    } else if (error.response.status === 404) {
      alert('系统配置错误，请联系管理员!')
    }
    return Promise.reject(error.response)
  }
)

export function request(config: AxiosRequestConfig, api = apiInst) {
  return api(config)
}

export function httpRequest(url: string, method: Method, parms = null, data = null) {
  const config: AxiosRequestConfig = {
    url: url,
    method: method,
    data: data,
    params: parms
  }

  return request(config)
}

export function httpGet(url: string, parms = null, data = null) {
  return httpRequest(url, HTTP_GET, parms, data)
}

export function httpPost(url: string, data = null, parms = null) {
  return httpRequest(url, HTTP_POST, parms, data)
}

export function httpDelete(url: string, data = null, parms = null) {
  return httpRequest(url, HTTP_DELETE, parms, data)
}

export function httpPut(url: string, data = null, parms = null) {
  return httpRequest(url, HTTP_PUT, parms, data)
}

export function httpPatch(url: string, data = null, parms = null) {
  return httpRequest(url, HTTP_PATCH, parms, data)
}
