
// 校验密码
export function checkPassword(v: string, required = true) {
    const minSize = 8
    if (required === true) {
        if (!v) {
            return false || '当前字段不允许为空'
        }
    }
    // 长度不少于8位
    return v.length >= minSize || '密码不能少于' + minSize + '个字符'
}

// 校验密码重复输入
export function checkSamePassword(v: string, p: string, required = true) {
    if (required === true) {
        if (!v) {
            return false || '当前字段不允许为空'
        }
    }

    return v === p || '两次输入密码不一致'
}

// 校验用户姓名
export function checkRealName(v: string, required = true) {
    const maxSize = 10
    if (required === true) {
        if (!v) {
            return false || '当前字段不允许为空'
        }
    }
    // 长度不大于10
    return v.length <= maxSize || '当前字段不能超过' + maxSize + '字符'
}

// 校验用户昵称
export function checkNickName(v: string, required = true) {
    const maxSize = 20
    if (required === true) {
        if (!v) {
            return false || '当前字段不允许为空'
        }
    }
    // 长度不大于10
    return v.length <= maxSize || '当前字段不能超过' + maxSize + '字符'
}

// 检查手机号合法性
export function checkPhone(v: string, required = true) {
    if (required === true) {
        if (!v) {
            return false || '当前字段不允许为空'
        }
    }
    // 必须是1开头，第二位数字可以是0-9任意一个，总长为11
    const reg = /^1([0-9])\d{9}$/
    return reg.test(v) === true || '手机号码格式不正确'
}

// 检查邮箱格式
export function checkMail(v: string, required = true) {
    if (required === true) {
        if (!v) {
            return false || '当前字段不允许为空'
        }
    }
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return !v || pattern.test(v) || '邮箱格式不正确'
}

// 检查手机验证码
export function checkRandCode(v: string, required = true) {
    if (required === true) {
        if (!v) {
            return false || '当前字段不允许为空'
        }
    }

    return v.length > 0 || '当前字段不允许为空'
}
