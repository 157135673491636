
<script>
import { TEST_TYPE_SOLID_SPREAD, TEST_MODE_NORMAL } from '@/const'
import BaseTest from './BaseTest'

export default {
  mounted() {
    if (this.$route.params !== null) {
      if (this.$route.params.routingMode === 'checkError') {
        this.getErrors(this.$route.params.testId)
      }
    }
  },
  data() {
    return {
      initImage: 'initImages/solid_spread.jpg',
      title: '立体展开测试',
      titleErrors: '立体展开测试 -- 查看错题',
      testType: TEST_TYPE_SOLID_SPREAD,
      testConfig: {
        title: '立体展开测试',
        testType: TEST_TYPE_SOLID_SPREAD,
        mode: TEST_MODE_NORMAL,
      },
    }
  },
  extends: BaseTest,
}
</script>

<style lang="scss" scoped>
</style>
