
<script>
import { TEST_TYPE_SCHULTE_GRID, TEST_MODE_NORMAL } from '@/const'
import BaseTest from './BaseTest'

export default {
  data() {
    return {
      initImage: 'initImages/schuteGrid.jpg',
      title: '舒尔特方格练习',
      titleErrors: '舒尔特方格练习',
      testType: TEST_TYPE_SCHULTE_GRID,
      testConfig: {
        title: '舒尔特方格练习',
        testType: TEST_TYPE_SCHULTE_GRID,
        mode: TEST_MODE_NORMAL,
      },
    }
  },
  extends: BaseTest,
}
</script>

<style lang="scss" scoped>
</style>
