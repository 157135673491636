<template>
  <div id="timer-counter-m">
    <table border="0" width="90%" class="table">
      <tr>
        <td align="center" class="timer-counter">
          <p class="cell-word">{{ dTimerName }}</p>
          <p>{{ counterToS }}S</p>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'time-counter-component',
  mounted() {
    if (this.timerName) {
      this.dTimerName = this.timerName
    }
    if (this.intervalMs) {
      this.dIntervalMs = this.intervalMs
    }
    this.$once('hook:beforeDestroy', () => {
      if (this.dCounterId) {
        clearInterval(this.dCounterId)
        this.dCounterId = null
      }
    })
  },
  watch: {
    timerName: {
      handler() {
        if (this.timerName) {
          this.dTimerName = this.timerName
        }
      },
    },
    intervalMs: {
      handler() {
        if (this.intervalMs) {
          this.dIntervalMs = this.intervalMs
        }
      },
    },
    counterStart: {
      handler() {
        if (this.counterStart === true) {
          this.startTimeCounter()
        } else if (this.counterStart === false) {
          this.stopTimeCounter()
        }
      },
    },
  },
  props: {
    // 计时器名称
    timerName: null,
    intervalMs: null,
    counterStart: null,
  },
  data() {
    return {
      //   计时器名称
      dTimerName: '计时器',
      //   计时器时间，毫秒为单位
      dCounterToMs: 0,
      //   计时器间隔, 默认50ms
      dIntervalMs: 50,
      //   计时器句柄
      dCounterId: null,
    }
  },
  methods: {
    //   计时器处理
    timeCounter() {
      this.dCounterToMs += this.dIntervalMs
    },
    // 启动计时器
    startTimeCounter() {
      this.dCounterToMs = 0
      if (this.dCounterId) {
        clearInterval(this.dCounterId)
      }
      this.dCounterId = setInterval(this.timeCounter, this.dIntervalMs)
    },
    // 停止计时器
    stopTimeCounter() {
      if (this.dCounterId) {
        clearInterval(this.dCounterId)
        this.dCounterId = null
        // 通过事件触发，通知上级当前的计时器值，ms单位
        this.$emit('timerStop', this.dCounterToMs)
      }
    },
    // 获取计时器值
    getCounterValue() {
      return this.dCounterToMs
    },
    // 清除计时器值
    clearCounterValue() {
      this.dCounterToMs = 0
    },
  },
  computed: {
    //   按照秒单位显示
    counterToS() {
      return (this.dCounterToMs / 1000).toFixed(2)
    },
  },
}
</script>

<style lang="scss" scoped>
.table {
  align-content: center;
  margin: auto;
}
.timer-counter {
  font-size: 150%;
  font-weight: bold;
  background: rgb(106, 182, 135);
  color: blue;
  // background-color: khaki;
  padding: 5% 0 1% 0;
}
.cell-word {
  font-size: small;
  color: white;
  align-content: center;
}
.v-application p {
  margin-bottom: 1px;
}
</style>
