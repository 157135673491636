
<script>
import { TEST_TYPE_PATTERN_MATCH, TEST_MODE_NORMAL } from '@/const'
import BaseTest from './BaseTest'

export default {
  mounted() {
    if (this.$route.params !== null) {
      if (this.$route.params.routingMode === 'checkError') {
        this.getErrors(this.$route.params.testId)
      }
    }
  },
  data() {
    return {
      // 每题中子题目数量
      qImgCount: 20,
      initImage: 'initImages/pattern_match.jpg',
      title: '图形匹配测试',
      titleErrors: '图形匹配测试 -- 查看错题',
      testType: TEST_TYPE_PATTERN_MATCH,
      testConfig: {
        title: '图形匹配测试',
        testType: TEST_TYPE_PATTERN_MATCH,
        mode: TEST_MODE_NORMAL,
      },
    }
  },
  methods: {
    // 重载方法，设置启动测试参数
    postData() {
      return { question_img_count: this.qImgCount }
    },
  },
  extends: BaseTest,
}
</script>

<style lang="scss" scoped>
</style>
