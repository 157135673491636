
<script>
import { getUserTestData } from '@/api/test-api'
import { formatSecondsToStr, formatTime } from '@/utils'
import { TEST_TYPE_ROUTING_MAP } from '@/const'

export default {
  // mounted() {
  //   this.getDataFromApi()
  // },
  data() {
    return {
      // 延迟加载标志，用于未获取到当前用户时使用
      pending: false,
      // 全局查询输入间隔控制
      searchTimer: null,
      //   全局查询字段
      search: '',
      //   datatable 记录总数
      totalRecords: 0,
      //   数据记录
      records: [],
      loading: true,
      //   datatable 查询选项, 用于数据查询及更新
      options: {},
      //   表头
      headers: [
        {
          text: '测试ID',
          align: 'start',
          value: 'test_code',
        },
        { text: '类型', value: 'test_type' },
        { text: '题目数量', value: 'q_count' },
        { text: '答题数量', value: 'answered' },
        { text: '正确数量', value: 'correct' },
        { text: '花费时长', value: 'spend_str' },
        { text: '状态', value: 'state' },
        { text: '测试日期', value: 'test_date' },
        { text: '开始时间', value: 'start_str' },
        { text: '结束时间', value: 'end_str' },
        { text: '查看错题', value: 'actions', sortable: false },
      ],
    }
  },
  watch: {
    // 监控data table查询的信息
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    // 监控全局搜索框
    search: {
      handler() {
        this.globalSearch()
      },
      deep: true,
    },
    // 是否延迟加载
    pending: {
      handler() {
        if (this.pending) {
          this.pending = false
          this.searchTimer = setTimeout(() => {
            this.getDataFromApi()
          }, 500)
        }
      },
    },
  },
  methods: {
    // 根据全局搜索条件进行延迟查询
    globalSearch() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer)
      }
      if (this.search) {
        this.searchTimer = setTimeout(() => {
          this.getDataFromApi()
        }, 500)
      } else {
        this.getDataFromApi()
      }
    },
    // 从后端获取数据并更新
    getDataFromApi() {
      this.searchTimer = null
      this.loading = true
      const parms = {
        page: this.options.page,
        results: this.options.itemsPerPage,
        search_global__icontains: this.search,
      }
      if (this.options.sortBy.length > 0) {
        // 仅支持一个字段的排序
        parms.sortField = this.options.sortBy[0]
        parms.sortOrder =
          this.options.sortDesc[0] === false ? 'ascend' : 'descend'
      } else {
        parms.sortField = null
        parms.sortOrder = null
      }
      //   进查询当前用户的记录
      parms.username = this.$store.getters.getUserName
      if (parms.username == null) {
        // 未获取到当前用户，一般在刷新页面时发生, 设置延迟加载
        this.pending = true
        return
      }

      getUserTestData(parms).then((data) => {
        this.records = []
        for (const item of data.data) {
          const spend_str = formatSecondsToStr(item.spend_time)
          const start_str = formatTime(item.start_time)
          const end_str = formatTime(item.end_time)
          item.spend_str = spend_str
          item.start_str = start_str
          item.end_str = end_str
          item.complete_rate = ((item.complete_rate * 1000) / 10).toFixed(2) + '%'
          item.correct_rate = ((item.correct_rate * 1000) / 10).toFixed(2) + '%'
        }
        this.records = data.data
        this.totalRecords = data.info.total
        this.loading = false
      })
    },
    // 查看错题
    checkError(data) {
      let url = ''
      for (const item of TEST_TYPE_ROUTING_MAP) {
        if (item.test_type === data.test_type) {
          url = item.url
          break
        }
      }
      const target = '/main/' + url
      const params = {
        testId: data.id,
        routingMode: 'checkError',
      }

      this.$router.push({
        path: target,
        name: url,
        params: params,
      })
    },
    // 是否可以查看历史错题记录
    disableCheckErrors(item) {
      if (item.answered > item.correct) {
        return false
      }
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
