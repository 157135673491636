<script>
import ChartPie from '@/components/charts/eChartPie.vue'
import ChartLine from '@/components/charts/eChartLine.vue'
import {
  getUserTestTypeSummaryInfo,
  getUserTestStatisticsInfo,
} from '@/api/test-api'
import { TEST_TYPES, TEST_TYPE_PLANE_LOGIC_NAME } from '@/const'

export default {
  components: { ChartPie, ChartLine },
  mounted() {
    if (this.getUserInfo) {
      this.retrieveUserTodayTestInfo()
      this.retrieveUserTestSummary()
      this.retireveUserTestStatisticsInfo()
    }
  },
  watch: {
    testType: {
      handler() {
        this.retireveUserTestStatisticsInfo()
      },
      deep: true,
    },
    getUserInfo: {
      handler() {
        if (this.getUserInfo) {
          this.retrieveUserTodayTestInfo()
          this.retrieveUserTestSummary()
          this.retireveUserTestStatisticsInfo()
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      // 今日汇总数据
      todayTestSummary: {
        chartTitle: '今日练习汇总',
        chartName: '',
        chartData: [],
        showPieData: true,
      },
      // 历史汇总数据
      testSummary: {
        chartTitle: '历史练习汇总',
        chartName: '',
        chartData: [],
        showPieData: true,
      },
      // 完成率数据
      completeRate: {
        chartLineTitle: '完成率统计',
        chartLineName: '完成率',
        xData: [],
        yData: [],
        averageLine: true,
        maxPoint: true,
        minPoint: true,
      },
      // 正确率数据
      correctRate: {
        chartLineTitle: '正确率统计',
        chartLineName: '正确率',
        xData: [],
        yData: [],
        averageLine: true,
        maxPoint: true,
        minPoint: true,
      },
      // 完成时间数据
      totalTime: {
        chartLineTitle: '完成时间统计(秒)',
        chartLineName: '完成时间',
        xData: [],
        yData: [],
        averageLine: true,
        maxPoint: true,
        minPoint: true,
      },
      // 平均时间统计
      averageTime: {
        chartLineTitle: '每题平均时间统计(秒)',
        chartLineName: '每题平均时间',
        xData: [],
        yData: [],
        averageLine: true,
        maxPoint: true,
        minPoint: true,
      },
      // 统计测试类型
      testType: TEST_TYPE_PLANE_LOGIC_NAME,
      // 统计数据点个数
      limit: 100,
      // 测试类型列表
      testTypeOptions: TEST_TYPES,
    }
  },
  methods: {
    // 从后端获取用户测试数据
    getUserTestSumaryData(chartData, parms = null) {
      getUserTestTypeSummaryInfo(parms).then((res) => {
        for (const item of res.data) {
          chartData.push({
            value: item.count,
            name: item.test_type,
          })
        }
        if (chartData.length === 0) {
          chartData.push({
            value: 0,
            name: '无练习记录',
          })
        }
      })
    },
    //   获取用户当天测试数据
    retrieveUserTodayTestInfo() {
      this.todayTestSummary.chartData = []
      this.getUserTestSumaryData(this.todayTestSummary.chartData)
    },
    // 获取用户历史测试数据
    retrieveUserTestSummary() {
      const parms = {
        day_before: -1,
      }
      this.testSummary.chartData = []
      this.getUserTestSumaryData(this.testSummary.chartData, parms)
    },
    // 获取用户测试统计数据
    retireveUserTestStatisticsInfo() {
      const parms = {
        test_type: this.testType,
        limit: this.limit,
      }
      getUserTestStatisticsInfo(parms).then((res) => {
        if (res.data.length === 0) {
          // 构造数据
          const testDate = ['无练习记录']
          const nullData = [0]
          // 完成率数据
          this.completeRate.xData = testDate
          this.completeRate.yData = nullData
          // 正确率数据
          this.correctRate.xData = testDate
          this.correctRate.yData = nullData
          // 完成时间数据
          this.totalTime.xData = testDate
          this.totalTime.yData = nullData
          // 平均时间
          this.averageTime.xData = testDate
          this.averageTime.yData = nullData
          return
        }
        // 构造数据
        const testDate = res.data.map((item) => item.test_date)
        // 完成率数据，百分比
        this.completeRate.xData = testDate
        this.completeRate.yData = res.data.map(
          (item) => Math.round(item.complete_rate * 1000) / 10
        )
        // 正确率数据，百分比
        this.correctRate.xData = testDate
        this.correctRate.yData = res.data.map(
          (item) => Math.round(item.correct_rate * 1000) / 10
        )
        // 完成时间数据
        this.totalTime.xData = testDate
        this.totalTime.yData = res.data.map((item) => item.spend_time)
        // 平均时间
        this.averageTime.xData = testDate
        this.averageTime.yData = res.data.map((item) => item.average_time)
      })
    },
  },
  computed: {
    getUserInfo() {
      return this.$store.getters.getUserInfo
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
