
<script>
import { httpPost } from '@/api/request'
import { getUserInfo } from '@/api/user-api/userApi'

export default {
  mounted() {
    this.username = 'demouser'
    this.password = '11111111'
    const data = {
      username: this.username,
      password: this.password,
    }
    httpPost(this.loginUrl, data, null).then(() => {
      this.$router.push('/home')
      // // 获取用户信息
      // getUserInfo().then(() => {
      //   // console.log('getuser: ', res)
      //   this.$router.push('/home')
      // })
    })
  },

  data() {
    return {
      username: 'demouser',
      password: '11111111',
      loginUrl: 'auth/login'
    }
  },

}
</script>

<style lang="scss" scoped>
</style>
