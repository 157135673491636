export * from './screen'

// 后端服务配置
export const APP_PREFIX = 'logic-test'
// export const SERVER_CONFIG = 'http://127.0.0.1:8006'

// 后端功能配置
export const FUNCTION_SYSTEM = 'system'
export const FUNCTION_CODE_CALC = 'code_calc'
export const FUNCTION_PLANE_LOGIC = 'plane_logic'
export const FUNCTION_SOLID_ROTATION = 'solid_rotation'
export const FUNCTION_SOLID_SPREAD = 'solid_spread'
export const FUNCTION_PATTERN_MATCH = 'pattern_match'
export const FUNCTION_SCHULTE_GRID = 'schulte_grid'
export const FUNCTION_MENTAL_CALC = 'mental_calc'

// 后端对应功能名称
export const TEST_TYPE_PLANE_LOGIC_NAME = '平面逻辑'
export const TEST_TYPE_SOLID_ROTATION_NAME = '立体旋转'
export const TEST_TYPE_SOLID_SPREAD_NAME = '立体展开'
export const TEST_TYPE_CODE_CALCULATION_NAME = '代码计算'
export const TEST_TYPE_PATTERN_MATCH_NAME = '图形匹配'

export const TEST_TYPES = [
    TEST_TYPE_PLANE_LOGIC_NAME,
    TEST_TYPE_SOLID_ROTATION_NAME,
    TEST_TYPE_SOLID_SPREAD_NAME,
    TEST_TYPE_CODE_CALCULATION_NAME,
    TEST_TYPE_PATTERN_MATCH_NAME
]

// 对应游戏名称
export const TEST_TYPE_SCHULTE_GRID_NAME = '舒尔特方格'
export const TEST_TYPE_MENTAL_CALC_NAME = '心算练习'

// 测试类型路由配置
export const TEST_TYPE_PLANE_LOGIC = 'plane-logic'
export const TEST_TYPE_SOLID_ROTATION = 'solid-rotation'
export const TEST_TYPE_SOLID_SPREAD = 'solid-spread'
export const TEST_TYPE_CODE_CALCULATION = 'code-calc'
export const TEST_TYPE_PATTERN_MATCH = 'pattern-match'
export const TEST_TYPE_SCHULTE_GRID = 'schulte-grid'
export const TEST_TYPE_MENTAL_CALC = 'mental-calc'

// 测试类型路由匹配设置
export const TEST_TYPE_ROUTING_MAP = [
    { test_type: TEST_TYPE_PLANE_LOGIC_NAME, url: TEST_TYPE_PLANE_LOGIC },
    { test_type: TEST_TYPE_SOLID_ROTATION_NAME, url: TEST_TYPE_SOLID_ROTATION },
    { test_type: TEST_TYPE_SOLID_SPREAD_NAME, url: TEST_TYPE_SOLID_SPREAD },
    { test_type: TEST_TYPE_CODE_CALCULATION_NAME, url: TEST_TYPE_CODE_CALCULATION },
    { test_type: TEST_TYPE_PATTERN_MATCH_NAME, url: TEST_TYPE_PATTERN_MATCH },
    { test_type: TEST_TYPE_SCHULTE_GRID_NAME, url: TEST_TYPE_SCHULTE_GRID },
    { test_type: TEST_TYPE_MENTAL_CALC_NAME, url: TEST_TYPE_MENTAL_CALC },
]

// 模式
// 普通测试模式
export const TEST_MODE_NORMAL = 'test_normal'
// 查看错题模式
export const TEST_MODE_ERRORS = 'test_errors'

// 用户账号状态
export const USER_ACCOUNT_ADMIN = '管理员账号'
export const USER_ACCOUNT_TRIAL = '试用账号'
export const USER_ACCOUNT_NOTMAL = '正常账号'
export const USER_ACCOUNT_DISABLE = '停用账号'

export const USER_ACCOUNT_STATES = [
    USER_ACCOUNT_ADMIN,
    USER_ACCOUNT_TRIAL,
    USER_ACCOUNT_NOTMAL,
    USER_ACCOUNT_DISABLE
]

// 全部菜单项
export const logicMenus = [
    {
        text: '历史记录',
        icon: 'mdi-history',
        route: '/main/user-history',
        func: ''
    },
    {
        text: '平面逻辑',
        icon: 'mdi-floor-plan',
        route: '/main/plane-logic',
        func: FUNCTION_PLANE_LOGIC,
    },
    {
        text: '立体旋转',
        icon: 'mdi-screen-rotation',
        route: '/main/solid-rotation',
        func: FUNCTION_SOLID_ROTATION,
    },
    {
        text: '立体展开',
        icon: 'mdi-cube-unfolded',
        route: '/main/solid-spread',
        func: FUNCTION_SOLID_SPREAD,
    },
    {
        text: '图形计算',
        icon: 'mdi-calculator-variant-outline',
        route: '/main/code-calc',
        func: FUNCTION_CODE_CALC,
    },
    {
        text: '图形匹配',
        icon: 'mdi-dice-multiple-outline',
        route: '/main/pattern-match',
        func: FUNCTION_PATTERN_MATCH,
    },
    {
        text: '舒尔特方格',
        icon: 'mdi-grid',
        route: '/main/schulte-grid',
        func: FUNCTION_SCHULTE_GRID,
    },
    {
        text: '心算练习',
        icon: 'mdi-head-snowflake-outline',
        route: '/main/mental-calc',
        func: FUNCTION_SCHULTE_GRID,
    },
    {
        text: '用户管理',
        icon: 'mdi-account',
        route: '/main/user',
        func: FUNCTION_SYSTEM,
    },
]

// 一级分隔符
export const SEPARATE_FLAG = ','
// 二级分隔符
export const SEPARATE_SUB_FLAG = ';'

// 登录图片数量
export const LOGIN_IMAGE_COUNT = 5

// 游戏相关配置
export const GAME_MODE_NORMAL = 'normal'

export const GAME_TYPE_SCHULTE_GRID = 'schulte_grid'
export const GAME_TYPE_MENTAL_CALC = 'mental_calc'

// 心算练习每个测试题目数量
export const GAME_MENTAL_CALC_SUB_COUNT = 5
