
<script>
import {
  addUserGameInfo,
  getUserGameHighestRecords,
  getUserGameRecords,
} from '@/api/test-api'
export default {
  mounted() {
    this.$once('hook:beforeDestroy', () => {
      if (this.dCounterId) {
        clearInterval(this.dCounterId)
        this.dCounterId = null
      }
    })
  },
  data() {
    return {
      //   计时器名称
      dTimerName: '计时器',
      //   计时器时间，毫秒为单位
      dCounterToMs: 0,
      //   计时器间隔, 100ms
      dIntervalMs: 100,
      //   计时器句柄
      dCounterId: null,
      // 显示完成对话框
      showResult: false,
      // 游戏启动标志
      gameStart: false,
      //   游戏难度配置
      gameConfig: {},
      //   用户选择难度
      selectedDifficulty: 'normal',
      //   游戏信息
      gameInfo: {
        gameType: '',
        gameMode: '',
        gameSubCount: '',
        gameData: [],
        difficulty: 'normal',
        startTime: null,
        endTime: null,
        spendTimeMS: null,
        spendTimeS: null,
        averageRate: null,
      },
      //   更新最高纪录
      refreshHigh: false,
      // 是否新纪录
      newRecord: false,
      //   最高纪录
      highestRecord: [],
      // 用户游戏纪录数据
      chartTitle: '游戏数据统计',
      chartName: '',
      xData: [],
      yData: [],
      averageLine: true,
      dataLimit: 30,
    }
  },
  methods: {
    //   可重载方法, 生成测试数据
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    generateGameData() {},
    // 可重载，配置游戏启动参数
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    configStartGameParms() {},
    // 可重载方法：获取题目中子项数量，用于计算平均值
    getSubCount() {
      return parseInt(this.gameInfo.gameSubCount)
    },
    // 可重载方法，游戏结束时配置参数
    setEndGameInfo() {
      this.gameInfo.spendTimeMS = this.getCounterValue()
      this.gameInfo.spendTimeS = parseFloat(
        (this.gameInfo.spendTimeMS / 1000).toFixed(3)
      )
      this.gameInfo.averageRate = (
        this.gameInfo.spendTimeS / this.getSubCount()
      ).toFixed(3)
    },
    // 可重载方法：停止游戏时，重置游戏参数
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setStopGameParms() {},
    // 重置游戏题目，用于游戏结束
    resetGameInfo() {
      this.gameInfo.gameData = []
      this.gameInfo.startTime = null
      this.gameInfo.endTime = null
      this.gameInfo.spendTimeMS = 0
      this.gameInfo.spendTimeS = 0
    },
    // 获取用户最高纪录
    retrieveHighestRecord() {
      const parms = {
        game_type: this.gameInfo.gameType,
      }
      getUserGameHighestRecords(parms).then((res) => {
        this.highestRecord = []
        for (const item of res.data) {
          this.highestRecord.push(item)
        }
        this.refreshHigh = false
      })
    },
    // 获取用户游戏记录
    retrieveUserGameRecords() {
      const parms = {
        game_type: this.gameInfo.gameType,
        game_sub_type: this.gameInfo.gameSubCount,
        game_difficulty: this.gameInfo.difficulty,
        limit: this.dataLimit,
      }
      getUserGameRecords(parms).then((res) => {
        this.xData = res.data.map((item) => item.game_date)
        this.yData = res.data.map((item) => item.spend_time / 1000)
      })
    },
    //   开始游戏
    startGame() {
      this.gameInfo.difficulty = this.selectedDifficulty
      this.gameInfo.startTime = new Date()
      this.gameInfo.endTime = null
      this.gameInfo.spendTimeMS = null
      this.gameInfo.spendTimeS = null
      this.generateGameData()
      this.gameStart = true
      this.showResult = false
      this.startTimeCounter()
      this.configStartGameParms()
    },
    // 结束游戏
    endGame() {
      // 停止计时器
      this.stopTimeCounter()
      this.gameInfo.endTime = new Date()
      this.setEndGameInfo()
      const gData = {
        game_type: this.gameInfo.gameType,
        game_mode: this.gameInfo.gameMode,
        game_sub_type: this.gameInfo.gameSubCount,
        game_difficulty: this.gameInfo.difficulty,
        start_time: this.gameInfo.startTime,
        end_time: this.gameInfo.endTime,
        spend_time: this.gameInfo.spendTimeMS,
        remark: JSON.stringify(this.gameInfo.gameData),
      }
      addUserGameInfo(gData).then(() => {
        this.gameStart = false
        this.newRecord = this.ifNewRecord()
        this.showResult = true
      })
    },
    // 停止游戏
    stopGame() {
      this.gameStart = false
      this.resetGameInfo()
      this.stopTimeCounter()
      this.setStopGameParms()
    },
    // 完成游戏对话框确认
    dialogConfirm() {
      // 关闭对话框
      this.showResult = false
      this.stopGame()
      // 获取统计数据
      this.retrieveUserGameRecords()
      // 更新最新纪录
      if (this.refreshHigh === true) {
        this.retrieveHighestRecord()
      }
    },
    // 获取最高记录
    getHighestRecord() {
      let find = false
      for (const item of this.highestRecord) {
        if (
          item.game_mode === this.gameInfo.gameMode &&
          parseInt(item.game_sub_type) === this.gameInfo.gameSubCount &&
          item.game_difficulty === this.gameInfo.difficulty
        ) {
          find = true
          const timeS = (item.highest_record / 1000).toFixed(3)
          return parseFloat(timeS)
        }
      }
      if (find === false) {
        return 0
      }
    },
    ifNewRecord() {
      const highest = this.getHighestRecord()
      if (highest === 0) {
        this.refreshHigh = true
        return true
      }
      if (this.gameInfo.spendTimeS === null) {
        return false
      }
      if (this.gameInfo.spendTimeS < highest) {
        this.refreshHigh = true
        return true
      } else {
        return false
      }
    },
    //   计时器处理
    timeCounter() {
      this.dCounterToMs += this.dIntervalMs
    },
    // 启动计时器
    startTimeCounter() {
      this.dCounterToMs = 0
      if (this.dCounterId) {
        clearInterval(this.dCounterId)
      }
      this.dCounterId = setInterval(this.timeCounter, this.dIntervalMs)
    },
    // 停止计时器
    stopTimeCounter() {
      if (this.dCounterId) {
        clearInterval(this.dCounterId)
        this.dCounterId = null
        // 通过事件触发，通知上级当前的计时器值，ms单位
        this.$emit('timerStop', this.dCounterToMs)
      }
    },
    // 获取计时器值
    getCounterValue() {
      return this.dCounterToMs
    },
    // 清除计时器值
    clearCounterValue() {
      this.dCounterToMs = 0
    },
  },
  computed: {
    //   按照秒单位显示
    counterToS() {
      return (this.dCounterToMs / 1000).toFixed(2)
    },
    // 读取屏幕宽度
    getWindowWidth() {
      return this.$store.getters.getScreenWidth
    },
    // 读取屏幕高度
    getWindowHeight() {
      return this.$store.getters.getScreenHeight
    },
  }
}
</script>
