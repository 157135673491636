// 获取指定日期后的时间
export function getSpecificDate(offset) {
    const today = new Date()
    today.setDate(today.getDate() + offset)
    const format_date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()

    return format_date
}

// 格式化时间为 YYYY-MM-DD HH:MM:SS
export function formatDateTime(dateTimeStr: string) {
    if (!dateTimeStr) {
        return ''
    }
    const formatDate = new Date(dateTimeStr)
    let dateTime = formatDate.getFullYear() + '-' + (formatDate.getMonth() + 1) + '-' + formatDate.getDate()
    dateTime += ' ' + formatDate.getHours() + ':' + formatDate.getMinutes() + ':' + formatDate.getSeconds()
    return dateTime
}

// 格式化时间为：HH:MM:SS
export function formatTime(dateTimeStr: string) {
    if (!dateTimeStr) {
        return ''
    }
    const formatDate = new Date(dateTimeStr)
    const dateTime = formatDate.getHours() + ':' + formatDate.getMinutes() + ':' + formatDate.getSeconds()
    return dateTime
}

// 解析答案选项
export function answerOptions(count: number, answer) {
    const alphaAnswers = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N']
    const numAnswers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']

    if (alphaAnswers.includes(answer)) {
        return alphaAnswers.slice(0, count)
    } else if (numAnswers.includes(answer)) {
        return numAnswers.slice(0, count)
    } else {
        return []
    }
}

// 检查元素是否在数组内
export function itemInArray(v: any, array: []) {
    for (const item of array) {
        if (v === item) {
            return true
        }
    }
    return false
}

// 将秒转化为 M分S秒
export function formatSecondsToStr(v: any) {
    const seconds = v % 60
    const minute = (v - seconds) / 60

    if (minute > 0) {
        return minute + '分钟' + seconds + '秒'
    } else {
        return seconds + '秒'
    }
}

// 比较两个整数
export function intCompare(v1: number, v2: number) {
    if (v1 < v2) {
        return -1
    } else if (v1 > v2) {
        return 1
    } else {
        return 0
    }
}

// 生成 m ~ n 之间的随机整数
export function getRandomInt(maxNum: number, minNum = 0) {
    const randomNum = Math.floor(Math.random() * (maxNum - minNum + 1) + minNum)
    return randomNum
}

// 随机打乱数组元素
export function arrayShuffle(arr) {
    let i = arr.length
    let j = i
    let k = i

    while (i) {
        j = Math.floor(Math.random() * i--)
        k = arr[i]
        arr[i] = arr[j]
        arr[j] = k
    }
}

// 生成随机字符串
export function getRandomString(len, chars = null) {
    let charList
    if (chars) {
        charList = chars
    } else {
        charList = '0123456789'
    }

    const maxPos = charList.length
    let randStr = ''
    for (let i = 0; i < len; i++) {
        randStr += charList.charAt(Math.floor(Math.random() * maxPos))
    }
    return randStr
}
