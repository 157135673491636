<template>
  <div id="baseTest">
    <router-view></router-view>
  </div>
</template>

<script>
import { TEST_MODE_NORMAL, TEST_MODE_ERRORS } from '@/const'
import {
  getErrorsApi,
  startTestApi,
  submitAnswerApi,
  doneTestApi,
} from '@/api/test-api'

export default {
  // components: { ShowResult },
  data() {
    return {
      // 提交答案等待对话框
      waitResultDialog: false,
      // 显示结果对话框
      ShowResultDialog: false,
      // testConfig: testConfigPlaneLogic,
      testResult: null,
      testData: null,
      questions: null,
      title: '',
      titleErrors: '',
      testType: '',
      testConfig: null,
    }
  },
  methods: {
    // 重载方法，构造开始测试参数
    postData() {
      return null
    },
    // 开始测试
    startTest(test_type) {
      const data = this.postData()
      startTestApi(test_type, data).then((res) => {
        this.testConfig.title = this.title
        this.testConfig.mode = TEST_MODE_NORMAL
        this.testConfig.testType = this.testType
        this.questions = res.data.question_details
        this.testData = res.data
        this.$store.commit('setTestStart', true)
      })
    },
    // 提交问题答案
    submitAnswer(data, test_type) {
      submitAnswerApi(test_type, data).then((res) => {
        this.questions[res.data.q_index - 1].q_result = res.data.q_result
      })
    },
    // 结束测试
    doneTest(data, test_type) {
      this.testResult = null
      this.waitResultDialog = true
      setTimeout(() => {
        doneTestApi(test_type, data).then((res) => {
          this.$store.commit('setTestStart', false)
          this.testResult = res.data
          // this.waitResultDialog = false
          // this.ShowResultDialog = true
          this.questions = null
          this.testData = null
        })
      }, 500)
    },

    // 关闭对话框
    closeResultDialog() {
      // this.ShowResultDialog = false
      this.waitResultDialog = false
    },
    // 查看错题
    checkErrors(data) {
      // this.ShowResultDialog = false
      this.waitResultDialog = false
      this.getErrors(data.id)
      // getErrorsApi(data.id).then((res) => {
      //   this.testConfig.title = this.titleErrors
      //   this.testConfig.mode = TEST_MODE_ERRORS
      //   this.testData = res.data
      //   // 更新错误问题数量
      //   this.testData.q_count = res.data.error_details.length
      //   this.questions = res.data.error_details
      // })
    },
    // 根据测试集id获取错误题目
    getErrors(testId) {
      getErrorsApi(testId).then((res) => {
        this.testConfig.title = this.titleErrors
        this.testConfig.mode = TEST_MODE_ERRORS
        this.testData = res.data
        // 更新错误问题数量
        this.testData.q_count = res.data.error_details.length
        this.questions = res.data.error_details
      })
    },
  },
  computed: {},
}
</script>

<style lang="scss" scoped>
</style>
