<template>
  <div
    class="echart"
    ref="echartLine"
    :style="{ float: 'left', width: '90%', height: '330px', margin: '0 auto' }"
  ></div>
</template>

<script>
import * as echarts from 'echarts'
import eChartBase from './eChartBase'

export default {
  props: {
    // 是否要平均线
    averageLine: null,
    // 是否显示最大值
    maxPoint: null,
    // 是否显示最小值
    minPoint: null,
    // y轴是否按照百分比显示
    percent: null,
    // y轴格式化
    yFormatter: null,
    // y轴是否显示单位
    yUnit: null,
  },
  data() {
    return {
      // 额外线条数据
      lineData: [],
      // 标记特殊点数据
      pointData: [],
      dChartXData: [],
      dChartYData: [],
      // tooltip 格式
      toolTipFormatter: '',
      // y轴格式
      yAxisFormatter: '{value}',
      // 特殊点格式
      markPointFormatter: '{c}',
      // 特殊线格式
      markLineFormatter: '{c}',
    }
  },
  methods: {
    // 重载方法
    initSpecParmsWhenMount() {
      if (this.averageLine === true) {
        this.lineData = [{ type: 'average', name: '平均值' }]
      }
      if (this.maxPoint === true) {
        this.pointData.push({ type: 'max', name: '最大值' })
      }
      if (this.minPoint === true) {
        this.pointData.push({
          type: 'min',
          name: '最小值',
          formatter: '{value}%',
        })
      }
      if (this.percent === true) {
        // 百分比格式
        this.toolTipFormatter = '{b}<br> {a}: {c}%'
        this.yAxisFormatter = '{value}%'
        this.markPointFormatter = '{c}%'
        this.markLineFormatter = '{c}%'
      }
      if (this.yFormatter) {
        this.yAxisFormatter = this.yFormatter
      }
      if (this.yUnit) {
        // Y轴格式添加单位
        this.yAxisFormatter = '{value} ' + this.yUnit
        // 数据点提示信息添加单位
        this.toolTipFormatter = '{b}<br> {a}: {c} ' + this.yUnit
        // 特殊点添加单位
        this.markPointFormatter = '{c} ' + this.yUnit
        // 特殊线添加单位
        this.markLineFormatter = '{c} ' + this.yUnit
      }
    },
    //   重载方法，获取echarts实例
    getEchartInst() {
      return echarts.init(this.$refs.echartLine)
    },
    // 重载方法，设置options
    getOption() {
      if (this.dChartOption) {
        return this.dChartOption
      }
      const option = {
        title: {
          text: this.dChartTitle,
          left: 'center',
        },
        tooltip: {
          trigger: 'axis',
          formatter: this.toolTipFormatter,
        },
        // legend: {
        //   data: [this.chartName],
        //   bottom: '0%',
        // },
        grid: {
          top: '20%',
          left: '5%',
          right: '10%',
          bottom: '5%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.dChartXData,
        },
        yAxis: {
          type: 'value',
          min: function (value) {
            let minV = Math.floor(value.min)
            if (minV === value.min) {
              minV = value.min - 1
            }
            return minV
          },
          max: function (value) {
            return Math.ceil(value.max + 1)
          },
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: this.yAxisFormatter,
          },
        },
        series: [
          {
            name: this.dChartName,
            type: 'line',
            data: this.dChartYData,
            // 标记点
            markPoint: {
              data: this.pointData,
              label: {
                formatter: this.markPointFormatter,
              },
            },
            // 标记平均线
            markLine: {
              data: this.lineData,
              label: {
                formatter: this.markLineFormatter,
              },
            },
          },
        ],
      }
      return option
    },
  },
  extends: eChartBase,
}
</script>

<style lang="scss" scoped>
</style>
