
<script>
import { TEST_TYPE_PLANE_LOGIC, TEST_MODE_NORMAL } from '@/const'
import BaseTest from '@/views/common/BaseTest'
// import ShowResult from '@/components/mobile/showResult.vue'
// import waitResultDialog from '@/components/mobile/waitRespDialog.vue'

export default {
  // components: { OptionsComponent, ShowResult, waitResultDialog },
  mounted() {
    if (this.$route.params !== null) {
      if (this.$route.params.routingMode === 'checkError') {
        this.getErrors(this.$route.params.testId)
      }
    }
  },
  data() {
    return {
      initImage: 'initImages/plane_logic.jpg',
      title: '平面逻辑测试',
      titleErrors: '平面逻辑测试 -- 查看错题',
      testType: TEST_TYPE_PLANE_LOGIC,
      testConfig: {
        title: '平面逻辑测试',
        testType: TEST_TYPE_PLANE_LOGIC,
        mode: TEST_MODE_NORMAL,
      },
    }
  },
  extends: BaseTest,
}
</script>

<style lang="scss" scoped>
</style>
