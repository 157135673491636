
<script>
// import store from '@/store'
import { httpGet } from '@/api/request'
import { getUserInfo, updateUserPassword } from '@/api/user-api/userApi'
import { logicMenus, USER_ACCOUNT_TRIAL } from '@/const'
import { itemInArray } from '@/utils'
import { checkPassword, checkSamePassword } from '@/views/common/userUtils.ts'

// getUserInfo()

export default {
  // beforeCreate() {
  //   getUserInfo()
  // },
  mounted() {
    getUserInfo().then(() => {
      if (this.isPublicAccount) {
        // 演示账号
        console.log('public account')
        this.trialAccountDialog = true
      } else if (this.isTrial) {
        // 试用账号
        console.log('trial account')
        this.trialDialog = true
      }
    })
  },
  data() {
    return {
      // 验证修改密码表单
      validUpdPwd: true,
      // 演示账号说明对话框
      trialAccountDialog: false,
      // 试用账号说明对话框
      trialDialog: false,
      // snackbar
      snackbar: false,
      // snackbar 内容
      snackBarMessage: '',
      // 用户头像
      imgUrl: require('../../assets/defPhoto.png'),
      // 左侧导航栏控制项
      drawer: true,
      // 导航栏选中项索引
      selectedItem: 1,
      // 导航栏菜单
      userMenu: [],
      // 上方工具栏菜单
      toolBarMenu: [
        { text: '练习数据', func: this.showDashboard },
        { text: '用户中心', func: this.personalCenter },
        { text: '修改密码', func: this.changePassword },
        { text: '退出登录', func: this.signOut },
      ],
      // trial 菜单不允许修改密码
      toolBarMenuTrial: [
        { text: '练习数据', func: this.showDashboard },
        { text: '用户中心', func: this.personalCenter },
        { text: '退出登录', func: this.signOut },
      ],
      // 修改密码对话框控制项
      updPwdDialog: false,
      // 是否显示旧密码
      showOldPassword: false,
      // 是否显示新密码
      showNewPassword: false,
      // 原密码
      // oldPassword: '',
      // 新密码
      newPassword: '',
      rules: {
        password: (v) => {
          return checkPassword(v)
        },
        samePwd: (v) => {
          return checkSamePassword(v, this.newPassword)
        },
      },
    }
  },
  methods: {
    // 显示试用账号转正方式
    showTrial() {
      this.trialDialog = true
    },
    // 修改密码
    changePassword() {
      this.updPwdDialog = true
    },
    // 取消修改密码
    cancelUpdPwdDialog() {
      // this.oldPassword = ''
      this.newPassword = ''
      this.updPwdDialog = false
    },
    // 保存新密码
    saveNewPassword() {
      const data = {
        // old_password: this.oldPassword,
        new_password: this.newPassword,
      }
      updateUserPassword(data).then((res) => {
        this.snackbar = true
        this.snackBarMessage = '修改密码成功!'
        // this.oldPassword = ''
        this.newPassword = ''
        this.updPwdDialog = false
        this.$router.push('/login')
      })
    },
    // 跳转到Dashboard
    showDashboard() {
      this.$router.push('/main/dashboard')
    },
    // 用户中心
    personalCenter() {
      this.$router.push('/main/user-center')
    },
    // 退出登录
    signOut() {
      if (this.$store.getters.isTestStart === true) {
        // 当前测试进行中，清除测试状态
        this.$store.commit('setTestStart', false)
      }
      httpGet('auth/logout')
        .then(() => {
          this.$store.dispatch('setUser', null)
          this.$router.push('/login')
        })
        .catch((err) => {
          alert('logout failed: ', err)
        })
    },
    // 更新菜单项
    generateMenus() {
      if (!this.$store.getters.getUserInfo) {
        return this.userMenu
      }
      const functions = this.$store.getters.getUserInfo.functions
      this.userMenu.length = 0
      // this.userMenu = logicMenus.map((item) => item.func )
      for (const item of logicMenus) {
        if (!item.func) {
          this.userMenu.push(item)
        } else if (itemInArray(item.func, functions) === true) {
          this.userMenu.push(item)
        }
      }
      return this.userMenu
    },
  },
  computed: {
    isTrial() {
      const account_state = this.$store.getters.getUserAccountState
      if (account_state === USER_ACCOUNT_TRIAL) {
        return true
      } else {
        return false
      }
    },
    // 当前用户昵称
    currentUser() {
      return this.$store.getters.getUserNickName
    },
    // 当前用户
    menu() {
      if (this.userMenu.length !== 0) {
        return this.userMenu
      }
      return this.generateMenus()
    },
    // 是否公用演示用户
    isPublicAccount() {
      const username = this.$store.getters.getUserName
      if (username === 'demouser') {
        return true
      } else {
        return false
      }
    },
    getbarMenu() {
      if (this.isPublicAccount) {
        return this.toolBarMenuTrial
      } else {
        return this.toolBarMenu
      }
    },
    userValidDate() {
      return this.$store.getters.getUserValidDate
    }
  },
}
</script>

<style lang="scss" scoped>
.username {
  text-align: center;
  color: white;
  font-size: medium;
  margin-top: 3px;
}
</style>
