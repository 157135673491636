
<script>
import { arrayShuffle } from '@/utils'
import {
  addUserGameInfo,
  getUserGameHighestRecords,
  getUserGameRecords,
} from '@/api/test-api'
import { GAME_MODE_NORMAL, GAME_TYPE_SCHULTE_GRID } from '@/const'
// import ChartLine from '@/components/charts/eChartLine'

export default {
  // components: { ChartLine },
  mounted() {
    this.retrieveHighestRecord()
    this.retrieveUserGameRecords()
    this.$once('hook:beforeDestroy', () => {
      if (this.counterId) {
        clearInterval(this.counterId)
      }
    })
  },
  watch: {
    gameNum: {
      handler() {
        if (this.gameStart === true) {
          //   当前游戏进行中，不操作
          return
        }
        // this.setCellStyle()
        this.gameInfo.gridNum = this.gameNum
        this.gameInfo.gameData = this.generateBaseData()
        this.initCellStyle()
      },
    },
    difficulty: {
      handler() {
        if (this.gameStart === true) {
          // 当前游戏进行中，不操作
          return
        }
        this.gameInfo.difficulty = this.difficulty
      },
    },
    gameInfo: {
      handler() {
        this.retrieveUserGameRecords()
      },
      deep: true,
    },
  },
  data() {
    return {
      // 显示完成对话框
      showResult: false,
      // 游戏启动标志
      gameStart: false,
      //   游戏网格数配置
      gameConfig: {},
      //   用户选择网格数
      gameNum: '3',
      //   游戏信息
      gameInfo: {
        gameType: GAME_TYPE_SCHULTE_GRID,
        gameMode: GAME_MODE_NORMAL,
        gridNum: '3',
        gameData: [1, 2, 3, 4, 5, 6, 7, 8, 9],
        difficulty: 'easy',
        startTime: null,
        endTime: null,
        spendTimeMS: null,
        spendTimeS: null,
        averageRate: null,
      },

      //   更新最高纪录
      refreshHigh: false,
      // 是否新纪录
      newRecord: false,
      //   期待数字
      expectNum: 0,
      //   游戏难度
      difficulty: 'easy',
      //   网格样式
      gridStyle: {
        'font-size': '300%',
        'font-weight': 'bold',
        color: '#255e95',
        height: '0',
        padding: '9% 0 9% 0',
        'background-color': 'khaki',
        'user-select': 'none',
      },
      //   最高纪录
      highestRecord: [],
      //   计时器句柄
      counterId: null,
      //   计时器时间
      gameTimeMS: 0,
      //   计时器间隔, 50ms
      timerInterval: 50,
      // 用户游戏纪录数据
      chartTitle: '游戏数据统计',
      chartName: '游戏时间',
      xData: [],
      yData: [],
      averageLine: true,
      // 单元格格式化
      paddingMap: {
        3: '9%',
        4: '5%',
        5: '4%',
        6: '2.5%',
        7: '1%',
        8: '0.5%',
        9: '0',
      },
    }
  },
  methods: {
    // 生成单元格id
    getCellId(xIdx, yIdx) {
      return 'cell_' + xIdx + '_' + yIdx
    },
    // 初始化单元格样式
    initCellStyle() {
      const gridNum = parseInt(this.gameInfo.gridNum)
      for (let xIdx = 1; xIdx < gridNum + 1; xIdx++) {
        for (let yIdx = 1; yIdx < gridNum + 1; yIdx++) {
          const cell = document.getElementById(this.getCellId(xIdx, yIdx))
          if (cell) {
            cell.style.backgroundColor = 'khaki'
          }
        }
      }
    },
    // 设置单元格样式
    // setCellStyle() {
    //   const paddingPercent = this.paddingMap[this.gameNum]
    //   this.gridStyle.padding = paddingPercent + ' 0 ' + paddingPercent + ' 0'
    // },
    // 获取用户最高纪录
    retrieveHighestRecord() {
      const parms = {
        game_type: GAME_TYPE_SCHULTE_GRID,
      }
      getUserGameHighestRecords(parms).then((res) => {
        this.highestRecord = []
        for (const item of res.data) {
          this.highestRecord.push(item)
        }
        this.refreshHigh = false
      })
    },
    // 获取用户游戏记录
    retrieveUserGameRecords() {
      const parms = {
        game_type: GAME_TYPE_SCHULTE_GRID,
        game_sub_type: this.gameInfo.gridNum,
        game_difficulty: this.gameInfo.difficulty,
        limit: 30,
      }
      getUserGameRecords(parms).then((res) => {
        this.xData = res.data.map((item) => item.game_date)
        this.yData = res.data.map((item) => item.spend_time / 1000)
      })
    },
    //   计时器
    timeCounter() {
      this.gameTimeMS += this.timerInterval
    },
    startTimeCounter() {
      this.gameTimeMS = 0
      if (this.counterId) {
        clearInterval(this.counterId)
      }
      this.counterId = setInterval(this.timeCounter, this.timerInterval)
    },
    stopTimeCounter() {
      clearInterval(this.counterId)
    },
    //   根据坐标生成数字
    getNumByXY(xIdx, yIdx) {
      return (xIdx - 1) * parseInt(this.gameInfo.gridNum) + (yIdx - 1)
    },
    // 生辰基本数组
    generateBaseData() {
      const gridNum = parseInt(this.gameNum)
      const array = []
      for (let i = 1; i < gridNum * gridNum + 1; i++) {
        array.push(i)
      }
      return array
    },
    //   生成随机数组
    generateRadomArray() {
      const array = this.generateBaseData()
      arrayShuffle(array)
      return array
    },
    //   开始游戏
    startGame() {
      this.gameInfo.gameData = this.generateRadomArray()
      // this.setCellStyle()
      this.initCellStyle()
      this.gameStart = true
      this.gameInfo.difficulty = this.difficulty
      this.gameInfo.gridNum = this.gameNum
      this.gameInfo.startTime = new Date()
      this.gameInfo.endTime = null
      this.gameInfo.spendTimeMS = null
      this.gameInfo.spendTimeS = null
      this.expectNum = 1
      this.showResult = false
      this.startTimeCounter()
      document.getElementById('schultegrid-table').scrollIntoView()
    },
    // 结束游戏
    endGame() {
      this.gameInfo.endTime = new Date()
      this.gameInfo.spendTimeMS = this.gameTimeMS
      this.gameInfo.spendTimeS = parseFloat(
        (this.gameInfo.spendTimeMS / 1000).toFixed(3)
      )
      this.gameInfo.averageRate = (
        this.gameInfo.spendTimeS /
        (this.gameInfo.gridNum * this.gameInfo.gridNum)
      ).toFixed(3)
      this.stopTimeCounter()
      const gData = {
        game_type: this.gameInfo.gameType,
        game_mode: this.gameInfo.gameMode,
        game_sub_type: this.gameInfo.gridNum,
        game_difficulty: this.gameInfo.difficulty,
        start_time: this.gameInfo.startTime,
        end_time: this.gameInfo.endTime,
        spend_time: this.gameInfo.spendTimeMS,
      }
      addUserGameInfo(gData).then((res) => {
        this.gameStart = false
        this.expectNum = 0
        this.newRecord = this.ifNewRecord()
        this.showResult = true
      })
    },
    // 重新开始游戏
    reStartGame() {
      this.startGame()
    },
    // 停止游戏
    stopGame() {
      this.gameStart = false
      this.expectNum = 0
      this.stopTimeCounter()
      this.initCellStyle()
    },
    // 完成游戏对话框确认
    dialogConfirm(restart) {
      // 关闭对话框
      this.showResult = false
      // 清空计时器
      this.gameTimeMS = 0
      // 获取统计数据
      this.retrieveUserGameRecords()

      // 更新最新纪录
      if (this.refreshHigh === true) {
        this.retrieveHighestRecord()
      }
      if (restart === true) {
        this.startGame()
      }
    },
    // 每个单元格显示数字
    showData(xIdx, yIdx) {
      if (this.gameInfo.gameData === []) {
        return ''
      }
      const dataIdx = this.getNumByXY(xIdx, yIdx)
      return this.gameInfo.gameData[dataIdx]
    },
    // 选择单元格
    select(xIdx, yIdx) {
      const dataIdx = this.getNumByXY(xIdx, yIdx)
      const curData = this.gameInfo.gameData[dataIdx]
      const cell = document.getElementById(this.getCellId(xIdx, yIdx))

      if (curData !== this.expectNum) {
        //   选择错误
        cell.style.color = 'red'
        setTimeout(() => {
          cell.style.color = '#255e95'
        }, 250)
        return
      }

      if (this.gameInfo.difficulty === 'easy') {
        // 简单模式，单元格变色
        cell.style.backgroundColor = 'gray'
      } else {
        // 困难模式，表格颜色变化表示点击
        cell.style.color = 'green'
        setTimeout(() => {
          cell.style.color = '#255e95'
        }, 200)
      }
      if (curData === this.gameInfo.gameData.length) {
        this.endGame()
        return
      }
      this.expectNum = this.gameInfo.gameData[dataIdx] + 1
    },
    // 获取最高记录
    getHighestRecord() {
      let find = false
      for (const item of this.highestRecord) {
        if (
          item.game_mode === this.gameInfo.gameMode &&
          item.game_sub_type === this.gameInfo.gridNum &&
          item.game_difficulty === this.gameInfo.difficulty
        ) {
          find = true
          const timeS = (item.highest_record / 1000).toFixed(3)
          return parseFloat(timeS)
        }
      }
      if (find === false) {
        return 0
      }
    },
    ifNewRecord() {
      const highest = this.getHighestRecord()
      if (highest === 0) {
        this.refreshHigh = true
        return true
      }
      if (this.gameInfo.spendTimeS === null) {
        return false
      }
      if (this.gameInfo.spendTimeS < highest) {
        this.refreshHigh = true
        return true
      } else {
        return false
      }
    },
  },
  computed: {
    gridSize() {
      return parseInt(this.gameInfo.gridNum)
    },
    counterToS() {
      return (this.gameTimeMS / 1000).toFixed(2)
    },
    highRecords() {
      return this.getHighestRecord()
    },
  },
}
</script>

<style lang="scss" scoped>
.table {
  align-content: center;
  margin: auto;
}
.expect-num {
  font-size: 150%;
  font-weight: bold;
  background: burlywood;
  color: red;
  padding: 1% 0 0 0;
}
.cell-word {
  font-size: small;
  color: white;
  align-content: center;
}
.highest-record {
  font-size: 150%;
  font-weight: bold;
  background: rgb(106, 182, 135);
  color: blue;
  // background-color: khaki;
  padding: 1% 0 0% 0;
}
.new-record {
  color: red;
  font-size: x-large;
}
.v-application p {
  margin-bottom: 1px;
}
</style>
