<script>
import {
  SCREEN_WIDTH_XS,
  SCREEN_WIDTH_SM,
  SCREEN_WIDTH_MD,
  SCREEN_WIDTH_LG,
} from '@/const'
export default {

  methods: {
    // 获取主区域宽度
    getMainAreaWidth() {
      // 32px padding, 256px drawer
      return this.$store.getters.getScreenWidth - 32 - 256
    },
    xsScreen() {
      const screenWidth = this.$store.getters.getScreenWidth
      if (screenWidth <= SCREEN_WIDTH_XS) {
        return true
      } else {
        return false
      }
    },
    smScreen() {
      const screenWidth = this.$store.getters.getScreenWidth
      if (screenWidth > SCREEN_WIDTH_XS && screenWidth <= SCREEN_WIDTH_SM) {
        return true
      } else {
        return false
      }
    },
    mdScreen() {
      const screenWidth = this.$store.getters.getScreenWidth
      if (screenWidth > SCREEN_WIDTH_SM && screenWidth <= SCREEN_WIDTH_MD) {
        return true
      } else {
        return false
      }
    },
    lgScreen() {
      const screenWidth = this.$store.getters.getScreenWidth
      if (screenWidth > SCREEN_WIDTH_MD && screenWidth <= SCREEN_WIDTH_LG) {
        return true
      } else {
        return false
      }
    },
    xlScreen() {
      const screenWidth = this.$store.getters.getScreenWidth
      if (screenWidth > SCREEN_WIDTH_LG) {
        return true
      } else {
        return false
      }
    },
    isLargeScreen() {
      // 超过960即为大屏
      const screenWidth = this.$store.getters.getScreenWidth
      if (screenWidth > SCREEN_WIDTH_SM) {
        return true
      } else {
        return false
      }
    },
    isMobileLargeScreen() {
      // 移动端超过600即为大屏
      const screenWidth = this.$store.getters.getScreenWidth
      if (screenWidth > SCREEN_WIDTH_SM) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
