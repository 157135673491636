<script>
import { APP_PREFIX, TEST_MODE_ERRORS } from '@/const'
import Base from '@/views/Base.vue'

export default {
  beforeMount() {
    this.initQuestionView()
  },
  props: {
    // 测试配置信息
    testConfig: null,
    // 题目列表
    questions: null,
    // 测试信息
    testData: null,
    // 题目说明图片
    initImage: null,
  },
  data() {
    return {
      // 计时器启动状态
      counterStart: false,
      // 计时器间隔
      timerIntervalMs: 100,
      // 用户选择的答案
      selectAnswer: '',
      // 答案选项
      answerOptions: [],
      // 测试集编码
      testCode: '',
      // 当前问题序号，从0开始，后端返回数据中q_index从1开始
      curQNum: null,
      // 下一题序号，用于提交答案后更新
      nextQNum: null,
      // 测试集题目数量
      qCount: null,
      // 测试集每页题目数量
      qSubCount: null,
      // 当前问题图片
      qImg: '',
      // 答案是否可见
      answerVisible: false,
      // 图片样式
      imgStyle: {
        display: 'inline-block',
        width: '90%',
        'max-width': '90%',
        height: '300px',
        margin: '0 auto',
      },
      // 提交答案按钮loading
      loadingSubmit: false
    }
  },
  watch: {
    // 检测当前题号，题号变化时，显示图片
    curQNum: {
      handler() {
        this.procQnumUpdate()
        this.updateQuestionView()
      },
      deep: true,
    },
    // 检测题目
    questions: {
      handler() {
        if (this.testData === null) {
          // 答题结束, 清除问题数据
          this.initQuestionView()
          this.loadingSubmit = false
        } else if (this.testCode !== this.testData.test_code) {
          // 测试编码更新，初始化数据
          this.setStartTestData()
          this.procNewTest()
          this.updateQuestionView()
        } else {
          // 更新问题答案，设置下一题
          this.curQNum = this.nextQNum
        }
      },
      deep: true,
    },
  },
  methods: {
    // 可重载方法
    // 问题序号更新处理
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    procQnumUpdate() {},
    // 开始新的测试处理
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    procNewTest() {},
    // 不同问题可重载此方法，初始化特殊的测试参数
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    specificInitTestData() {},
    // 不同问题可重载此方法，设置特殊的测试参数
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    specificSetStartTestData() {},
    // 获取当前问题答案，不同问题可重载
    getQuestionResult() {
      return this.selectAnswer
    },
    // // 不同问题可重载此方法
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    procNextQuestion() {},
    // 获取当前问题的题目信息，不同测试重载次方法设置answerOptions
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    getAnswerOptions() {},
    // 是否有效问题，不同测试重载此方法
    isValidResult(result) {
      if (result !== '') {
        return true
      } else {
        return false
      }
    },
    // 开始新测试，设置初始数据
    setStartTestData() {
      this.qCount = this.testData.q_count
      this.qSubCount = this.testData.q_subcount
      this.curQNum = 0
      this.testCode = this.testData.test_code
      this.specificSetStartTestData()
    },
    // 构造问题答案上报消息
    sendSubmitAnswerData(nextQ) {
      if (this.checkErrors === true) {
        // 查看错题模式，仅更新序号
        this.curQNum = nextQ
        return
      }
      const result = this.getQuestionResult()
      if (this.questions[this.curQNum].q_result === result) {
        // 当前问题答案未变化，不需要发送消息，直接更新题号
        this.curQNum = nextQ
      } else {
        // 发送消息更新问题答案
        const data = {
          test_code: this.testCode,
          q_index: this.questions[this.curQNum].q_index,
          q_result: result,
          spend_time: 0,
        }
        this.$emit('submitAnswer', data, this.testConfig.testType)
        this.nextQNum = nextQ
      }
    },
    // 初始化问题显示
    initQuestionView() {
      this.curQNum = null
      this.testCode = ''
      this.nextQNum = null
      this.qCount = null
      this.qSubCount = null
      this.selectAnswer = ''
      if (this.initImage !== null) {
        this.qImg = require('../assets/' + this.initImage)
      } else {
        this.qImg = ''
      }
      this.answerVisible = false
      this.specificInitTestData()
    },
    // 更新问题显示
    updateQuestionView() {
      if (this.questions === null) {
        return
      }
      this.getImage()
      this.getAnswerOptions()
    },
    // 获取问题图片
    getImage() {
      const image = this.questions[this.curQNum].q_image.replace(/#/g, '%23')
      // this.qImg = SERVER_CONFIG + '/' + APP_PREFIX + '/' + image
      this.qImg = '/' + APP_PREFIX + '/' + image
    },
    // 开始测试
    startTest() {
      // 通知上层组件，开始测试
      this.$emit('startTest', this.testConfig.testType)
      this.counterStart = true
      this.curQNum = 0
    },
    // 上一题
    preQuestion() {
      if (this.curQNum > 0) {
        this.sendSubmitAnswerData(this.curQNum - 1)
      } else {
        alert('当前为第一题！')
      }
    },
    // 下一题
    nextQuestion() {
      this.procNextQuestion()
      if (this.curQNum < this.qCount - 1) {
        this.sendSubmitAnswerData(this.curQNum + 1)
      } else {
        alert('当前为最后一题!')
      }
    },
    // 完成测试，提交答案
    doneTest() {
      // 发送当前问题答案，用于最后一道题完成时直接提交
      let data = null
      this.counterStart = false
      const result = this.getQuestionResult()
      if (this.isValidResult(result)) {
        data = {
          test_code: this.testCode,
          q_index: this.questions[this.curQNum].q_index,
          q_result: result,
          spend_time: 0,
        }
      } else {
        data = {
          test_code: this.testCode,
          spend_time: 0,
        }
      }
      this.$emit('doneTest', data, this.testConfig.testType)
      this.loadingSubmit = true
    },
    // 显示答案
    setAnswerVisible() {
      if (this.checkErrors) {
        this.answerVisible = !this.answerVisible
      }
    },
    // 禁用上一题按钮
    disablePreBtn() {
      if (this.disableNavBtn) {
        return true
      }
      if (this.curQNum === 0) {
        return true
      } else {
        return false
      }
    },
    // 禁用下一题按钮
    disableNextBtn() {
      if (this.disableNavBtn) {
        return true
      }
      if (this.curQNum >= this.qCount - 1) {
        return true
      } else {
        return false
      }
    },
  },
  computed: {
    // 测试页面题目是否为空
    isTestData() {
      if (this.testData === null) {
        return false
      } else {
        return true
      }
    },
    // 当前是否有进行中的测试
    isTestStart() {
      return this.$store.getters.isTestStart
    },
    // 当前是否为检查错题状态
    checkErrors() {
      if (this.testConfig.mode === TEST_MODE_ERRORS) {
        return true
      } else {
        return false
      }
    },
    // 正确答案
    correctAnswer() {
      return this.questions[this.curQNum].q_answer
    },
    // 是否显示答案
    showAnswer() {
      return this.checkErrors & this.answerVisible
    },
    // 禁用按钮
    disableNavBtn() {
      if (this.testConfig.mode === TEST_MODE_ERRORS) {
        // 查看错误模式，不禁用导航按钮
        return false
      } else {
        // 正常模式，根据测试是否启动进行设定
        return !this.isTestStart
      }
    },
    // 读取屏幕宽度
    getWindowWidth() {
      return this.$store.getters.getScreenWidth
    },
    // 读取屏幕高度
    getWindowHeight() {
      return this.$store.getters.getScreenHeight
    },
  },
  extends: Base,
}
</script>

<style lang="scss" scoped>
</style>
