import { httpDelete, httpGet, httpPost, HTTP_GET } from '../request'

export function startTestApi(testType, data = null) {
    const url = 'test-case/' + testType + '/start'
    return httpPost(url, data)
}

export function submitAnswerApi(testType, data = null) {
    const url = 'test-case/' + testType + '/submit'
    return httpPost(url, data)
}

export function doneTestApi(testType, data = null) {
    const url = 'test-case/' + testType + '/stop'
    return httpPost(url, data)
}

export function getErrorsApi(test_id) {
    const url = 'test-case/error-info/' + test_id
    return httpGet(url)
}

export function getUserTestData(parms) {
    const url = 'test-case/list'
    return httpGet(url, parms)
}

export function addUserGameInfo(data) {
    const url = 'game/list'
    return httpPost(url, data)
}

export function getUserGameHighestRecords(parms) {
    const url = 'game/highest-records'
    return httpGet(url, parms)
}

export function getUserGameRecords(parms) {
    const url = 'game/user-records'
    return httpGet(url, parms)
}

export function getUserTestTypeSummaryInfo(parms) {
    const url = 'test-case/test-type/summary'
    return httpGet(url, parms)
}

export function getUserTestStatisticsInfo(parms) {
    const url = 'test-case/test-type/statistics'
    return httpGet(url, parms)
}

export function getQuestionDescription(parms) {
    const url = 'test-case/question/info'
    return httpGet(url, parms)
}
