
<script>
import { userRegister, sendRegisterSms } from '@/api/user-api/userApi'
import {
  checkPassword,
  checkRealName,
  checkSamePassword,
  checkMail,
  checkPhone,
  checkNickName,
  checkRandCode
} from './userUtils.ts'
import { getRandomInt, getRandomString } from '@/utils'
import { LOGIN_IMAGE_COUNT } from '@/const'
import Base from '@/views/Base'

export default {
  data() {
    return {
      // 用户输入的验证码
      identifyCode: '',
      // 生成的验证码
      randomCode: '点击刷新',
      // 验证码按钮显示内容
      smsContent: '发送验证码',
      // 记录具体倒计时时间
      totalTime: 60,
      // canClick: true,
      countInst: null,
      mobileBlur: false,
      userInfo: {
        nickname: '',
        password: '',
        real_name: '',
        openid: '',
        mobile: '',
        id_card: '',
        email: '',
        rand_code: '',
      },
      valid: true,
      password: '',
      showPassword: false,
      rules: {
        nickname: (v) => {
          return checkNickName(v)
        },
        password: (v) => {
          return checkPassword(v)
        },
        realname: (v) => {
          return checkRealName(v)
        },
        samePwd: (v) => {
          return checkSamePassword(v, this.userInfo.password)
        },
        email: (v) => {
          return checkMail(v, false)
        },
        mobile: (v) => {
          return checkPhone(v)
        },
        randCode: (v) => {
          return checkRandCode(v)
        }
      },
    }
  },
  methods: {
    // 用户注册
    register() {
      userRegister(this.userInfo)
        .then(() => {
          alert('注册用户成功!')
          this.$router.push('/login')
        })
    },
    // 返回登录
    toLogin() {
      this.$router.push('/login')
    },
    // 更新验证码
    refreshCode() {
      this.randomCode = getRandomString(4)
    },
    // 验证码是否正确
    enableSendSms() {
      if (this.countInst) {
        return false
      }
      if (!this.userInfo.mobile) {
        return false
      }
      if (this.identifyCode !== this.randomCode) {
        return false
      }
      return true
    },
    // 更新按钮显示
    updateInterval() {
      this.totalTime--
      this.smsContent = this.totalTime + 's后重新发送'
      if (this.totalTime < 0) {
        window.clearInterval(this.countInst)
        this.countInst = null
        this.smsContent = '重新发送验证码'
        this.totalTime = 60
      }
    },
    // 通知后端发送手机验证码
    getMobileCode() {
      const data = {
        mobile: this.userInfo.mobile,
      }
      sendRegisterSms(data).then(() => {
        alert('验证码已发送，请查看手机')
        this.smsContent = this.totalTime + 's后重新发送'
        // this.identifyCode = ''
        this.refreshCode()
        this.countInst = window.setInterval(() => {
          this.updateInterval()
        }, 1000)
      })
    },
  },
  computed: {
    registerImage() {
      // 多个图片中随机选择
      const imgIdx = getRandomInt(LOGIN_IMAGE_COUNT)
      const imgName = 'login_' + imgIdx + '.png'
      return require('../../assets/loginImages/' + imgName)
    },
  },
  extends: Base,
}
</script>

<style lang="scss" scoped>
</style>
